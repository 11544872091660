// useMediaQuery.js
import { useState, useEffect } from "react";
import Debug from "./Debug";

// Custom Hook con query preimpostate
function useMediaQuery() {
    const queries = {
        mobile: '(max-width: 767px)',
        tablet: '(min-width: 768px) and (max-width: 1023px)',
        desktop: '(min-width: 1024px)'
    };

    const [matches, setMatches] = useState({
        mobile: window.matchMedia(queries.mobile).matches,
        tablet: window.matchMedia(queries.tablet).matches,
        desktop: window.matchMedia(queries.desktop).matches
    });

    useEffect(() => {
        const mediaQueryLists = {
            mobile: window.matchMedia(queries.mobile),
            tablet: window.matchMedia(queries.tablet),
            desktop: window.matchMedia(queries.desktop)
        };

        const updateMatches = () => {
            setMatches({
                mobile: mediaQueryLists.mobile.matches,
                tablet: mediaQueryLists.tablet.matches,
                desktop: mediaQueryLists.desktop.matches
            });
        };

        updateMatches(); // Controlla le media query all'inizio

        const listeners = Object.keys(mediaQueryLists).map(key => {
            const listener = () => updateMatches();
            mediaQueryLists[key].addEventListener('change', listener);
            return () => mediaQueryLists[key].removeEventListener('change', listener);
        });

        // Pulisci i listener quando il componente viene smontato
        return () => listeners.forEach(cleanup => cleanup());
    }, []);
    Debug.print("matches ", matches);
    return matches;
}

export default useMediaQuery;
