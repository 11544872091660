import React from 'react';
import defaultImage from "./../../assets/images/Orta.PNG";
import './../../css/components/heroic-text.scss'; // Importazione corretta del file SCSS
import ScrollDownArrow from "./ScrollDownArrow";

function HeroicText(props) {
    const immagine = props.img || defaultImage;

    return (
        <div className="container-fix heroic-text">
            <div className="immagine" style={{ backgroundImage: `url(${immagine})` }} alt="" />
            <div className="obscure text-center" style={props.color ? { backgroundColor: props.color } : null}>
                <div className="light">
                    <h1 className="text-transparent">{props.title || "TESTO EROICO"}</h1>
                    <h2 className="text-transparent">{props.subtitle}</h2>
                    <p className="text-transparent">{props.paragraph}</p>
                </div>
                {props.arrow === true && <ScrollDownArrow />}
            </div>
        </div>
    );
}

export default HeroicText;
