import "../css/bicolumn.scss";
import useMediaQuery from "../utils/useMediaQuery";
import defaultImage from "./../assets/images/Orta.PNG";
import ButtonLink from "./basics/ButtonLink";

function BiColumnImageText(props) {
    const { tablet, desktop } = useMediaQuery(); // Call the hook here
    const isWide = tablet || desktop;

    return (
        <div className="container-fluid bicolumn" id={props.id}>
            <div className="row alternating-row">
                <div className={`alternating-image${isWide ? ' w-50' : ''}`} >
                    {/* Aggiunta di loading="lazy" per il lazy loading delle immagini */}
                    <img src={props.img || defaultImage} loading="lazy" style={{ width: "100%" }} alt=""/>
                </div>
                <div className="col alternating-text">
                    <h1 dangerouslySetInnerHTML={{ __html: (props.text && props.text.title) || 'Titolo di default' }}></h1>
                    <p dangerouslySetInnerHTML={{ __html: (props.text && props.text.paragraph) || 'Testo di default' }}></p>
                    <ButtonLink link={props.link}></ButtonLink>
                </div>
            </div>
        </div>
    );
}

export default BiColumnImageText;
