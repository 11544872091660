import React from 'react';
import "../css/bicolumn.scss";
import useMediaQuery from "../utils/useMediaQuery";
import ButtonLink from "./basics/ButtonLink";
function BiColumnText(props) {
    const { tablet, desktop } = useMediaQuery();
    const isWide = tablet || desktop;

    return (
        <div className={"container-fluid bicolumn bicolumn-text " + props.classes}>
            <div className="row alternating-row">
                <div className={`text-center alternating-image${isWide ? ' w-50' : ''}`}>
                    {typeof props.text.otherText === 'string'
                        ? <div dangerouslySetInnerHTML={{ __html: props.text.otherText }} />
                        : props.text.otherText}
                </div>
                <div className="col alternating-text">
                    <h1>{(props.text && props.text.title) || 'Titolo di default'}</h1>
                    <p dangerouslySetInnerHTML={{ __html: (props.text && props.text.paragraph) || 'Testo di default' }} />
                    <ButtonLink link={props.link}></ButtonLink>
                </div>
            </div>
        </div>
    );
}
export default BiColumnText;
