import React, { useState } from 'react';
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink
} from 'reactstrap';
import { Link } from 'react-router-dom';
import useMediaQuery from '../utils/useMediaQuery';
import linksJson from './../assets/files/jsons/links.json';
import './../css/components/navbar.scss';
import logo from './../assets/images/Orta.PNG';


function OurNav(props) {
  const { tablet, desktop } = useMediaQuery();
  const isWide = tablet || desktop;
  const [collapsed, setCollapsed] = useState(true);
  const [openDropdownId, setOpenDropdownId] = useState(null);

  const toggleNavbar = () => setCollapsed(!collapsed);

  // Funzione per gestire l'apertura/chiusura dei dropdown in modalità desktop
  const toggleDropdown = (id) => {
    setOpenDropdownId(prevId => (prevId === id ? null : id));
  };

  // Funzione ricorsiva per generare i link e i sublink
  const renderNavItems = (links, parentPath = '', isSubLink = false) => {
    return links.map((link) => {
      const fullPath = `${parentPath}${link.path}`;

      // Se ci sono subLinks e siamo in visione mobile, mostra i sublink come elementi indentati
      if (!isWide && link.subLinks && link.subLinks.length > 0) {
        return (
          <React.Fragment key={link.id}>
            <NavItem>
              <NavLink tag={Link} to={fullPath}>
                {link.shownName}
              </NavLink>
            </NavItem>
            {/* Render subLinks come elementi indentati */}
            <div className="mobile-sublink-container">
              {renderNavItems(link.subLinks, fullPath, true)}
            </div>
          </React.Fragment>
        );
      }

      // Se ci sono subLinks e siamo in visione desktop, usa un dropdown custom
      if (isWide && link.subLinks && link.subLinks.length > 0) {
        return (
          <NavItem
            key={link.id}
            className="desktop-dropdown"
            onMouseLeave={() => setOpenDropdownId(null)}
          >
            <div className="desktop-link-with-dropdown">
              {/* Link principale cliccabile */}
              <NavLink tag={Link} to={fullPath}>
                {link.shownName}
              </NavLink>
              {/* Icona separata per aprire il dropdown */}
              <i
                className={`fa-solid fa-chevron-down ${openDropdownId === link.id ? 'open' : ''}`}
                onClick={() => toggleDropdown(link.id)}
              />
            </div>
            {openDropdownId === link.id && (
              <div className="custom-dropdown-menu">
                {renderNavItems(link.subLinks, fullPath, false)}
              </div>
            )}
          </NavItem>
        );
      }

      // Altrimenti, crea un normale elemento NavItem
      return (
        <NavItem key={link.id} className={isSubLink ? 'sublink-item' : ''}>
          <NavLink tag={Link} to={fullPath}>
            {link.shownName}
          </NavLink>
        </NavItem>
      );
    });
  };

  return (
    <Navbar color="faded" light>
      <NavbarBrand tag={Link} to="/">
        <img
          alt="logo"
          src={logo}
          style={{
            height: 100,
            borderRadius:"10px",
            padding: "0rem",
            margin: "0.3rem",
            backgroundColor: "white",
            // borderRadius: "50%"
          }}
        />
        
      </NavbarBrand>

      {!isWide ? (
        <>
          <i onClick={toggleNavbar} className="me-2 fa-solid fa-ellipsis-vertical"></i>
          <Collapse isOpen={!collapsed} navbar>
            <Nav navbar>
              {renderNavItems(linksJson)}
            </Nav>
          </Collapse>
        </>
      ) : (
        <Nav className='d-flex flex-row' id='desktop-nav' navbar>
          {renderNavItems(linksJson)}
        </Nav>
      )}
    </Navbar>
  );
}

export default OurNav;
